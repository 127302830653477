import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { TranslateService } from '@ngx-translate/core';

const parseKeyName = (keyName: string) => 'paginator.' + keyName;
@Injectable()
export class MatPaginatorI18NService extends MatPaginatorIntl {
  constructor(private translateService: TranslateService) {
    super();
    this._updateLabels();
    this.translateService.onLangChange.pipe(debounceTimeAfterFirst(100)).subscribe((ev) => {
      this._updateLabels();
    });
  }
  private async _updateLabels() {
    const keys = ['itemsPerPage', 'nextPage', 'previousPage', 'firstPage', 'lastPage'].map(parseKeyName);
    const translation = await this.translateService.get(keys).toPromise();
    this.itemsPerPageLabel = translation[parseKeyName('itemsPerPage')];
    this.nextPageLabel = translation[parseKeyName('nextPage')];
    this.previousPageLabel = translation[parseKeyName('previousPage')];
    this.firstPageLabel = translation[parseKeyName('firstPage')];
    this.lastPageLabel = translation[parseKeyName('lastPage')];
  }
}
