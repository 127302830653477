import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: 'input[isError]',
})
export class IsMatErrorDirective implements OnInit {
  private _isError: Object | null = null;
  constructor(private control: NgControl) {}
  @Input()
  set isError(val: Object) {
    this._isError = val;
    if (this.control) this.setStates(val);
  }
  get isError() {
    return this._isError;
  }
  ngOnInit(): void {
    this.setStates(this._isError);
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.setStates(this._isError);
    });
    console.log('isError', this.control);
  }
  private setStates(isError: typeof this._isError) {
    this.control.control.markAsDirty();
    this.control.control.markAsTouched();
    this.control.control.setErrors(isError);
  }
}
