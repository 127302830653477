import { Pipe, PipeTransform } from '@angular/core';
import { stringify } from '@app/_helpers/utils';
import type { ValueIteratee } from 'lodash';
import uniqBy from 'lodash-es/uniqBy';

@Pipe({
  name: 'uniqBy',
  standalone: true,
})
export class UniqByPipe implements PipeTransform {
  transform<T extends Array<{}>, TI extends ValueIteratee<T[0]> | ValueIteratee<T[0]>[], R = T | null>(
    value: T,
    iteratee: TI,
  ): R {
    const keys = [iteratee].flat();
    return ((value?.length && uniqBy(value, (x) => keys.map((k: any) => stringify(x[k] ?? null)).join('_'))) ||
      []) as R;
  }
}
