import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[tg-form-field-label],[tgFormLabel],[tgformlabel],[tg-form-label]',
  host: {
    class: 'tg-form-label',
  },
})
export class TgFormfieldLabelDirective {
  constructor(private el: ElementRef<HTMLElement>) {}
  addClasses() {}
}
