import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { SatPopoverComponent as SatPopover, SatPopoverAnchoringService } from '@ncstate/sat-popover';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[anchorFor]',
  exportAs: 'satPopoverAnchor',
  providers: [SatPopoverAnchoringService],
})
export class AnchorForPopup implements OnInit, OnDestroy {
  /** Reference to the popover instance. */
  @Input('anchorFor')
  get attachedPopover() {
    return this._attachedPopover;
  }
  set attachedPopover(value: any) {
    this._attachedPopover = value.elementRef || value._elementRef || value;
    // Anchor the popover to the element ref
    this._anchoring.anchor(this._elementRef, this._viewContainerRef, this.attachedPopover);
  }
  private _attachedPopover: ElementRef;
  @Input('matchFill')
  get matchFill() {
    return this._matchFill;
  }
  set matchFill(value: boolean) {
    this._matchFill = value || false;
  }
  private _matchFill: boolean = false;

  /** Emits when the popover is opened. */
  @Output() popoverOpened = new EventEmitter<void>();

  /** Emits when the popover is closed. */
  @Output() popoverClosed = new EventEmitter<any>();

  /** Emits when the directive is destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private _elementRef: SatPopover,
    private _viewContainerRef: ViewContainerRef,
    public _anchoring: SatPopoverAnchoringService,
  ) {}

  ngOnInit() {
    this._validateAttachedPopover(this._elementRef);
    // Re-emit open and close events
    const opened$ = this._anchoring.popoverOpened.pipe(tap(() => this.popoverOpened.emit()));
    const closed$ = this._anchoring.popoverClosed.pipe(tap((value) => this.popoverClosed.emit(value)));
    merge(opened$, closed$).pipe(takeUntil(this._onDestroy)).subscribe();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /** Gets whether the popover is presently open. */
  isPopoverOpen(): boolean {
    return this._anchoring.isPopoverOpen();
  }

  /** Toggles the popover between the open and closed states. */
  togglePopover(): void {
    this._anchoring.togglePopover();
  }

  /** Opens the popover. */
  openPopover(): void {
    this._anchoring.openPopover();
  }

  /** Closes the popover. */
  closePopover(value?: any): void {
    this._anchoring.closePopover(value);
  }

  /** Throws an error if the popover instance is not provided. */
  private _validateAttachedPopover(popover: SatPopover): void {
    if (!popover || !(popover instanceof SatPopover)) {
      // log.error(['Invalid Popover', popover]);
    }
  }
}
