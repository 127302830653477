import { Injectable } from '@angular/core';
import { Logger } from 'timeghost-api';
import { isObservable, of, BehaviorSubject, Observable, from } from 'rxjs';

const log = new Logger('KeyValStoreService');
@Injectable({
  providedIn: 'root'
})
export class KeyValStoreService {
  private data: Map<string, any> = new Map([]);

  constructor() {}
  get isLoadingActive() {
    return this.get('loading.active', true);
  }
  set isLoadingActive(val: boolean) {
    this.set('loading.active', val);
  }
  get isScrolled() {
    return this.get('scrolled.top', false);
  }
  set isScrolled(val: boolean) {
    this.set('scrolled.top', val);
  }
  getIsScrolledObservable() {
    return this.getObservable('scrolled.top');
  }
  get<T>(key: string, defaultValue?: T): T {
    key = key.toLowerCase();
    if (!this.has(key)) {
      return defaultValue;
    }
    let ret = this.data.get(key);
    if (ret instanceof BehaviorSubject) {
      return ret.value;
    }
    return ret as any;
  }
  getObservable<T>(key: string, defaultValue?: T): Observable<T> {
    key = key.toLowerCase();
    if (!this.has(key)) {
      return of(defaultValue);
    }
    let ret = this.data.get(key);
    if (ret instanceof BehaviorSubject) {
      return ret.asObservable();
    }
    if (ret instanceof Observable) {
      return ret;
    }
    return from<any>(ret) as Observable<T>;
  }
  private isBehaviour(key: string) {
    let entry = this.data.get(key);
    return {
      check: entry instanceof BehaviorSubject,
      value: entry
    };
  }
  set<T>(key: string, value: T | any, clearItem?: boolean) {
    key = key.toLowerCase();
    if (isObservable(value)) {
      log.debug(['observable added', value]);
      this.data.set(key, value);
    } else {
      let entry = this.isBehaviour(key);
      if (entry.check && !clearItem) {
        (entry.value as BehaviorSubject<T>).next(value);
      } else {
        this.data.set(key, new BehaviorSubject<T>(value));
      }
    }
  }
  has(key: string): boolean {
    return this.data.has(key);
  }
}
