import { Instance, LifecycleHooks, Props, Plugin as TippyPlugin } from 'tippy.js';

interface CustomProps {
  stretchToTrigger: boolean;
}

type FilteredProps = CustomProps & Omit<Props, keyof CustomProps | keyof LifecycleHooks>;

type ExtendedProps = FilteredProps & LifecycleHooks<FilteredProps>;
export const sameWidthPlugin: TippyPlugin<ExtendedProps> = {
  name: 'stretchToTrigger',
  defaultValue: false,
  fn(rootInstance) {
    const nameOfPlugin = rootInstance.plugins.find((d) => d === this)?.name ?? '-';
    const setContentSize = ({ popper, reference }: Instance<ExtendedProps>) => {
      const { width: refWidth, x: refLeft } = reference.getBoundingClientRect();
      popper.style.width = refWidth + 'px';
      if (rootInstance.setProps) {
        rootInstance.setProps({
          maxWidth: document.body.clientWidth - refLeft,
        });
      }
    };
    let resizeHandle: any;
    // const checkOptions = (instance: typeof rootInstance) => {
    //   const { stretchToTrigger } = instance.props as any;
    //   console.log('instance', { instance, popper: instance.popperInstance, stretchToTrigger });
    //   if (!instance.props.popperOptions) instance.props.popperOptions = {};
    //   if (!instance.props.popperOptions.modifiers) instance.props.popperOptions.modifiers = [];
    //   if (stretchToTrigger)
    //     instance.props.popperOptions.modifiers.push(sameWidth);
    // };
    return {
      onShow(instance) {
        const { popper, reference, props, state, ...args } = instance;
        if (props.stretchToTrigger) setContentSize({ popper, reference } as any);
        document.addEventListener('resize', (resizeHandle = setContentSize.bind(this, instance)), {
          passive: true,
        });
      },
      onDestroy() {
        if (resizeHandle) document.removeEventListener('resize', resizeHandle);
      },
    };
  },
};
