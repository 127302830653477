import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[tg-hint]',
  host: {
    class: 'tg-hint',
  },
})
export class TgHintDirective {
  constructor(private el: ElementRef<HTMLElement>) {
    this.addClasses();
  }
  getElement() {
    return this.el;
  }
  addClasses() {}
}
