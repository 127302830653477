import { extract } from '@app/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extract'
})
export class ExtractPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return extract(value);
  }
}
