import { Pipe, PipeTransform } from '@angular/core';
function isNumeric(value: string) {
  return /^-?\d+$/.test(value);
}
@Pipe({
  name: 'iterateObject',
})
export class IterateObjectPipe implements PipeTransform {
  transform<T extends Object>(_value: T, keyValuePair?: boolean, ignoreEnumNumber?: boolean): any[] {
    if (!_value) return null;
    if (typeof _value !== 'object') {
      throw new Error('Invalid Object');
    }
    let value = { ..._value };
    if (ignoreEnumNumber === true)
      Object.keys(value)
        .filter((x) => isNumeric(x))
        .forEach((key) => delete value[key]);
    if (keyValuePair === true) return Object.entries(value).map(([key, v]) => ({ key, value: v }));
    return Object.keys(value).map((x) => value[x]);
  }
}
