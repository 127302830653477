import { Overlay, OverlayContainer } from '@angular/cdk/overlay';
import { Inject, Injectable, InjectionToken, Injector, Optional, SkipSelf } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MAT_LEGACY_DIALOG_SCROLL_STRATEGY as MAT_DIALOG_SCROLL_STRATEGY,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { _MatSheetBase } from '../mat-side-sheet/mat-side-sheet';
import { MatRightSideSheetComponent as MatSideSheetComponent } from './mat-right-side-sheet.component';
export const MAT_SHEET_DEFAULT_OPTIONS = new InjectionToken<MatDialogConfig>('mat-right-sheet-default-options');

/**
 * Service to open Material Design modal dialogs.
 */
@Injectable()
export class MatRightSideSheet extends _MatSheetBase<MatSideSheetComponent> {
  constructor(
    overlay: Overlay,
    injector: Injector,
    @Optional() @Inject(MAT_SHEET_DEFAULT_OPTIONS) defaultOptions: MatDialogConfig,
    @Inject(MAT_DIALOG_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() @SkipSelf() parentDialog: MatRightSideSheet,
    overlayContainer: OverlayContainer,
  ) {
    super(
      overlay,
      injector,
      defaultOptions,
      parentDialog,
      overlayContainer,
      scrollStrategy,
      MatDialogRef,
      MatSideSheetComponent,
      MAT_DIALOG_DATA,
    );
  }
}
