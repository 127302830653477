import { Directive, Input, OnInit, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '@env/environment';
@Directive({
  selector: '[isDev]',
})
export class IsDevDirective implements OnInit {
  @Input()
  isBeta: boolean;
  constructor(private viewContainer: ViewContainerRef, @Optional() private templateRef: TemplateRef<any>) {}
  ngOnInit() {
    this.updateView();
  }
  private updateView() {
    if (environment.production) {
      this.viewContainer.clear();
    } else if (this.templateRef) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
