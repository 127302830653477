import { CdkVirtualForOf } from '@angular/cdk/scrolling';
import { NgForOf } from '@angular/common';
import { Directive, Host, Input, Optional } from '@angular/core';
import { get } from 'lodash-es';
const selectorDefault = ['ngForTrackProp', 'cdkVirtualForTrackProp', 'trackProp'];
@Directive({
  selector: `[${selectorDefault[0]}], [${selectorDefault[1]}], [${selectorDefault[2]}]`,
})
export class TrackPropDirective {
  private _propName: string = '';
  public constructor(
    @Host() @Optional() private readonly _ngFor: NgForOf<any>,
    @Host() @Optional() private readonly _virtualNgFor: CdkVirtualForOf<any>
  ) {
    if (_ngFor)
      this._ngFor.ngForTrackBy = (_: number, item: any) => (this._propName ? get(item, this._propName) : item);
    else if (_virtualNgFor)
      this._virtualNgFor.cdkVirtualForTrackBy = (_: number, item: any) =>
        this._propName ? get(item, this._propName) : item;
  }
  private setProp(name: string) {
    this._propName = name ?? '';
  }
  @Input(selectorDefault[0])
  public set setPropNameNG(value: string) {
    this.setProp(value);
  }
  @Input(selectorDefault[1])
  public set setPropNameCDK(value: string) {
    this.setProp(value);
  }
}
