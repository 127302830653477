import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateArgs',
  pure: false,
})
export class TranslateArgsPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform<T extends Object>(value?: T): T {
    if (!value || typeof value !== 'object') return {} as T;
    return Object.entries(value)
      .filter((val) => val?.length && val[0] && val[1])
      .map(([key, value]) => [key, String(value)])
      .reduce((acc, [key, translateValue]) => {
        if (key && typeof translateValue === 'string') acc[key] = this.translate.instant(translateValue);
        else if (key) acc[key] = translateValue;
        return acc;
      }, {} as T);
  }
}
