import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padRight',
  pure: true,
})
export class PadRightPipe implements PipeTransform {
  transform(value: string, pad: number, fillString?: string): string {
    return value.padEnd(pad, fillString);
  }
}
