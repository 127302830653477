import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TgInputDirective } from './tg-input.directive';
import { TgInputContainerDirective } from './tg-input-container.directive';
import { TgLabelDirective } from './tg-label.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TgFormfieldDirective } from './tg-formfield.directive';
import { TgHintDirective } from './tg-hint.directive';
import { TgSuffixDirective } from './tg-suffix.directive';
import { TgPrefixDirective } from './tg-prefix.directive';
import { TgFormfieldLabelDirective } from './tg-formfield-label.directive';

@NgModule({
  declarations: [
    TgInputDirective,
    TgInputContainerDirective,
    TgLabelDirective,
    TgFormfieldDirective,
    TgHintDirective,
    TgSuffixDirective,
    TgPrefixDirective,
    TgFormfieldLabelDirective,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [
    TgFormfieldDirective,
    TgFormfieldLabelDirective,
    TgHintDirective,
    TgInputContainerDirective,
    TgInputDirective,
    TgLabelDirective,
  ],
})
export class TgFormsModule {}
