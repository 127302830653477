import { Pipe, PipeTransform } from '@angular/core';
import { padStart } from 'lodash-es';

@Pipe({
  name: 'padLeft',
  pure: true,
})
export class PadLeftPipe implements PipeTransform {
  transform(value: string, pad: number, fillString?: string): string {
    return padStart(value, pad, fillString);
  }
}
