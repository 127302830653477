import { animate, state, style, transition, trigger, AnimationTriggerMetadata } from '@angular/animations';

/**
 * Animations used by MatDialog.
 * @docs-private
 */
export const matSheetAnimations: {
  readonly sheetContainer: AnimationTriggerMetadata;
} = {
  sheetContainer: trigger('sheetContainer', [
    // Note: The `enter` animation transitions to `transform: none`, because for some reason
    // specifying the transform explicitly, causes IE both to blur the dialog content and
    // decimate the animation performance. Leaving it as `none` solves both issues.
    state('void, exit', style({ opacity: 0, transform: 'translateX(300px)' })),
    state('enter', style({ transform: 'translateX(0)' })),
    transition(
      '* => enter',
      animate('200ms cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'translateX(0)', opacity: 1 }))
    ),
    transition(
      '* => void, * => exit',
      animate('125ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0, transform: 'translateX(300px)' }))
    ),
  ]),
};
