import { Directive, HostListener, Input } from '@angular/core';

@Directive({ selector: '[send-tagmanager_event]' })
export class SendTagDirective {
  @Input('send-tagmanager_event') option: any;

  @HostListener('click', ['$event']) onClick($event: any) {
    window.dataLayer.push({
      event: this.option.category,
      action: this.option.action,
    });
  }
  constructor() {}
}
