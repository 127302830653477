import { Directive, EventEmitter, Output, Renderer2, ElementRef, OnDestroy, OnInit, HostListener } from '@angular/core';

@Directive({
  selector: '[click.stop]',
})
export class ClickStopDirective {
  @Output('click.stop') stopEvent = new EventEmitter();

  constructor() {}
  @HostListener('click', ['$event'])
  onHostElementClick(ev: Event) {
    ev.stopPropagation();
    this.stopEvent.emit(...arguments);
  }
}
