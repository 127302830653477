import { Directive, Input, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { distinctUntilChangedJson } from '@app/_helpers/utils';
import { get } from 'lodash-es';
import { map } from 'rxjs';
import { Logger, UserSettingsQuery } from 'timeghost-api';
const log = new Logger('IsSettingsEnabledDirective');
@Directive({
  selector: '[isSettingsEnabled]',
})
export class IsSettingsEnabledDirective {
  @Input()
  isSettingsEnabled: string;
  constructor(
    private viewContainer: ViewContainerRef,
    @Optional() private templateRef: TemplateRef<any>,
    private userSettingsQuery: UserSettingsQuery
  ) {}
  ngOnInit() {
    this.updateView();
    this.userSettingsQuery
      .select()
      .pipe(
        map((x) => {
          return {
            user: x.settings,
            ws: x.workspace?.settings,
          };
        }),
        distinctUntilChangedJson()
      )
      .subscribe((values) => {
        this.updateView();
      });
  }
  private updateView() {
    const [cat, settingsKey] = ((s) => (s.length < 2 ? null : s))(this.isSettingsEnabled.split(':', 2)) ?? [
      null,
      this.isSettingsEnabled,
    ];
    const isWsSetting = cat === 'ws';
    const user = this.userSettingsQuery.getValue();
    const settings = isWsSetting ? user.workspace.settings : user.settings;
    const value = get(settings, settingsKey);
    log.debug(this.isSettingsEnabled, 'value:', value);
    if (!value && this.viewContainer.length) {
      this.viewContainer.clear();
    } else if (this.templateRef && !this.viewContainer.length) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
