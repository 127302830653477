import { Directive, ElementRef, Input, OnInit, ViewContainerRef, booleanAttribute } from '@angular/core';
import { AppService } from '@app/app.service';
import { isTeamsWindow } from '@env/msal';

declare const window: Window;

@Directive({
  selector: '[teams-hide],[teams-hide][teamsHideMobile]',
})
export class TeamsUiConditionDirective implements OnInit {
  constructor(
    private viewContainer: ViewContainerRef,
    private elRef: ElementRef,
    private appService: AppService,
  ) {}
  @Input({ alias: 'teams-hide', transform: booleanAttribute })
  teamsHide: boolean = true;
  @Input({ transform: booleanAttribute })
  teamsHideMobile?: boolean;
  ngOnInit() {
    const testTeams = window.teams_test,
      testTeamsMobile = testTeams === 'mobile';
    if (this.viewContainer?.element && this.teamsHide ? !!window.teams || testTeams || isTeamsWindow() : false) {
      if (!this.teamsHideMobile ? true : window.teamsMobile || testTeamsMobile) {
        this.viewContainer.clear();
        this.viewContainer.element.nativeElement?.remove();
      }
    }
  }
}
