import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceString',
})
export class ReplaceStringPipe implements PipeTransform {
  transform(
    value: string,
    find: string | RegExp,
    replaceWith?: string,
    regex?: boolean,
    regexArgs: string = '',
  ): unknown {
    return value.replace(regex ? new RegExp(find, regexArgs) : find, replaceWith);
  }
}
