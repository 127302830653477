import { Pipe, PipeTransform } from '@angular/core';
import { firstBy } from 'thenby';

@Pipe({
  name: 'sort',
})
export class ArraySortPipe implements PipeTransform {
  transform<T extends Array<any>>(value: T, sortBy: { [key: string]: 'asc' | 'desc' }, clone: boolean = false): T {
    const sorts = Object.entries(sortBy);
    const firstBySort = sorts.shift();
    let sortFn = sorts.reduce((sort, [key, value]) => {
      return sort.thenBy(key, value);
    }, (([key, value]) => firstBy<any>(key, value))(firstBySort));

    return (clone ? ([...value] as typeof value) : value).sort(sortFn);
  }
}
