export const IsBetaWindow = () => location.hostname.split('.')[0] === 'web-beta';
export const IsDevWindow = () => location.hostname.split('.')[0] === 'web-dev';
export const IsProductionWindow = () => location.hostname.split('.')[0] === 'web';
export const getSubDomain = () =>
  location.hostname === 'localhost'
    ? true
    : IsDevWindow()
    ? 'web-dev'
    : IsBetaWindow()
    ? 'web-beta'
    : IsProductionWindow()
    ? 'web'
    : null;
